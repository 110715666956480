var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a-layout',[_c('Row',[_c('div',{staticClass:"co-login__main"},[_c('div',{staticClass:"co-login__left"},[_c('RoltekHeaderLogo',{staticClass:"co-login__logo"}),_c('div',{staticClass:"c-reset-password"},[_c('div',{staticClass:"c-reset-password__inner"},[_c('div',{staticClass:"c-reset-password__upper-section"},[_c('h4',{staticClass:"u-a4 c-reset-password__message",attrs:{"data-test":"login-title"}},[_vm._v(" "+_vm._s(_vm.$t('Promijenite svoju lozinku ovdje'))+" ")])]),_c('a-form',{staticClass:"c-reset-password__form",attrs:{"colon":false,"layout":"inline","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"c-reset-password__form-fields"},[_c('a-form-item',{staticClass:"u-b1",attrs:{"label":_vm.$t('Lozinka'),"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                            'password',
                                            {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: this.$t('Molimo unesite svoju lozinku'),
                                                    },
                                                    {
                                                        validator: _vm.validateToNextPassword,
                                                    },
                                                ],
                                            },
                                        ]),expression:"[\n                                            'password',\n                                            {\n                                                rules: [\n                                                    {\n                                                        required: true,\n                                                        message: this.$t('Molimo unesite svoju lozinku'),\n                                                    },\n                                                    {\n                                                        validator: validateToNextPassword,\n                                                    },\n                                                ],\n                                            },\n                                        ]"}],attrs:{"type":"password"}})],1),_c('a-form-item',{staticClass:"u-b1",attrs:{"label":_vm.$t('Ponovi lozinku'),"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                            'passwordRepeat',
                                            {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: this.$t('Molimo potvrdite svoju lozinku'),
                                                    },
                                                    {
                                                        validator: _vm.compareToFirstPassword,
                                                    },
                                                ],
                                            },
                                        ]),expression:"[\n                                            'passwordRepeat',\n                                            {\n                                                rules: [\n                                                    {\n                                                        required: true,\n                                                        message: this.$t('Molimo potvrdite svoju lozinku'),\n                                                    },\n                                                    {\n                                                        validator: compareToFirstPassword,\n                                                    },\n                                                ],\n                                            },\n                                        ]"}],attrs:{"type":"password"},on:{"blur":_vm.handleConfirmBlur}})],1)],1),_c('a-form-item',{staticClass:"c-reset-password__form-row"},[_c('a-button',{staticClass:"c-reset-password__button",class:[{ '--is-disabled': _vm.httpRequestStarted }],attrs:{"type":"primary","data-test":"login-submit","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Promijeni lozinku'))+" "),(_vm.httpRequestStarted)?_c('AIcon',{staticClass:"c-reset-password__loader",attrs:{"type":"loading"}}):_vm._e()],1)],1)],1)],1)]),_c('span',{staticClass:"co-login__footer"},[_vm._v(" Copyright 2022 Roltek Inc. All rights reserved. ")])],1),_c('div',{staticClass:"co-login__right"},[_c('RoltekBanner')],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }