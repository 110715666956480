<template>
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="837.000000pt"
        height="160.000000pt"
        viewBox="0 0 837.000000 160.000000"
        preserveAspectRatio="xMidYMid meet"
    >
        <g transform="translate(0.000000,160.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path
                d="M220 1428 c-37 -22 -64 -49 -85 -82 l-30 -49 -3 -471 c-2 -419 -1
-476 14 -515 21 -55 66 -106 118 -134 l41 -22 2770 -3 c3082 -2 2827 -8 2901
66 77 77 75 64 74 593 0 434 -2 475 -18 513 -21 46 -79 102 -128 122 -28 12
-478 14 -2817 14 l-2783 0 -54 -32z m1655 -179 c159 -29 274 -128 325 -280 19
-54 21 -80 18 -164 -9 -199 -96 -324 -273 -391 -72 -27 -278 -27 -351 0 -126
47 -217 143 -256 269 -17 57 -20 87 -16 167 4 83 10 107 37 166 86 185 286
276 516 233z m-913 -20 c94 -20 163 -69 187 -132 13 -35 14 -137 2 -170 -16
-41 -69 -94 -117 -116 -29 -14 -42 -26 -38 -34 15 -25 139 -183 168 -215 17
-18 47 -37 68 -43 l38 -10 0 -54 0 -55 -129 0 -129 0 -24 28 c-12 16 -70 97
-128 180 -98 141 -107 151 -137 151 l-33 1 0 -100 c0 -87 3 -103 20 -120 11
-11 31 -20 45 -20 24 0 25 -3 25 -60 l0 -60 -207 2 -208 3 0 55 c0 52 1 55 26
58 15 2 35 12 45 24 17 18 19 43 22 272 3 280 0 293 -58 312 -33 11 -35 14
-38 63 l-3 51 275 0 c165 0 296 -4 328 -11z m1768 -44 c0 -49 -2 -55 -21 -55
-70 0 -79 -35 -79 -314 0 -282 0 -280 88 -284 102 -5 196 49 269 155 20 29 40
53 44 53 16 0 79 -34 79 -42 0 -5 -27 -74 -61 -153 l-61 -144 -336 2 -337 2 0
55 c0 52 1 55 26 58 15 2 35 12 45 24 17 18 19 41 19 277 0 285 -2 296 -60
307 -35 6 -35 6 -35 60 l0 54 210 0 210 0 0 -55z m1106 -70 c24 -66 44 -123
44 -127 0 -7 -64 -38 -79 -38 -4 0 -15 16 -26 35 -40 76 -120 132 -187 134
l-28 1 0 -264 c0 -288 5 -322 48 -332 53 -11 53 -11 50 -66 l-3 -53 -207 -3
-208 -2 0 60 c0 57 1 60 25 60 14 0 34 9 45 20 19 19 20 33 20 300 l0 280 -25
0 c-64 0 -137 -51 -182 -128 -27 -45 -27 -45 -92 -21 -17 7 -21 14 -16 27 4 9
25 68 47 130 l41 112 345 -2 345 -3 43 -120z m834 3 c30 -67 56 -124 58 -128
1 -3 -18 -16 -42 -27 l-44 -22 -28 40 c-60 87 -156 140 -265 147 -71 4 -77 3
-93 -19 -12 -17 -16 -46 -16 -106 l0 -83 44 0 c59 0 88 24 95 81 l6 44 45 0
45 0 3 -192 2 -193 -49 0 -48 0 -7 41 c-9 56 -52 99 -100 99 l-36 0 0 -124 c0
-138 4 -149 64 -160 104 -20 258 66 352 197 l20 28 41 -23 41 -24 -63 -144
-64 -145 -355 -3 -356 -2 0 60 c0 60 0 60 29 60 58 0 61 15 61 306 l0 263 -26
20 c-15 12 -35 21 -45 21 -16 0 -19 8 -19 55 l0 55 348 0 347 0 55 -122z m570
67 c0 -50 -2 -55 -22 -55 -48 -1 -63 -25 -66 -108 -7 -141 -11 -140 103 -37
112 100 115 103 115 127 0 12 -10 18 -32 20 -32 3 -33 4 -36 56 l-3 52 200 0
201 0 0 -55 c0 -30 -4 -55 -10 -55 -5 0 -34 -9 -64 -20 -56 -21 -86 -42 -175
-122 l-51 -46 97 -162 c117 -195 159 -246 214 -260 l40 -11 -3 -52 -3 -52
-220 0 -220 0 -3 51 -3 52 38 9 c59 14 55 38 -23 167 -37 61 -68 113 -70 114
-1 2 -23 -11 -48 -30 l-45 -33 -1 -77 c0 -99 9 -123 54 -138 35 -11 36 -12 36
-66 l0 -54 -205 0 -205 0 0 60 c0 56 2 60 23 60 13 0 32 6 43 14 18 14 19 30
22 280 l3 266 -25 25 c-14 14 -34 25 -46 25 -18 0 -20 6 -20 55 l0 55 205 0
205 0 0 -55z"
            />
            <path
                d="M1705 1135 c-51 -18 -99 -73 -122 -139 -14 -39 -18 -81 -18 -181 1
-113 3 -136 23 -180 42 -91 101 -135 182 -135 83 0 135 38 178 130 22 46 27
73 30 167 4 95 1 122 -18 184 -26 86 -73 141 -135 158 -51 14 -71 13 -120 -4z"
            />
            <path
                d="M700 1121 c-5 -11 -10 -65 -10 -120 l0 -101 58 0 c122 0 182 40 182
120 0 81 -52 120 -159 120 -49 0 -62 -3 -71 -19z"
            />
            <path
                d="M8023 1418 c-1 -17 -8 -35 -14 -38 -8 -6 -8 -10 1 -15 7 -4 17 -1 22
6 8 11 8 10 4 -4 -3 -10 -6 -28 -6 -38 0 -11 -4 -17 -10 -14 -21 13 -9 -7 25
-42 33 -34 37 -48 13 -48 -6 0 -13 -6 -16 -14 -3 -8 -1 -11 4 -8 10 6 11 -44
1 -60 -10 -16 3 -44 17 -36 8 4 8 2 -1 -7 -6 -7 -9 -20 -6 -29 4 -13 3 -14 -5
-1 -6 8 -9 17 -6 21 2 4 -1 11 -7 15 -7 4 -9 3 -6 -3 4 -6 0 -13 -8 -17 -17
-6 -20 -26 -4 -26 5 0 7 5 3 12 -4 7 -3 8 4 4 14 -9 4 -36 -11 -30 -9 3 -11
-94 -9 -398 2 -382 3 -402 22 -415 22 -16 24 -54 1 -45 -10 4 -11 7 -3 10 7 2
12 8 12 14 0 6 -6 6 -15 -2 -8 -7 -12 -16 -9 -21 3 -5 0 -9 -5 -9 -6 0 -11 -5
-11 -11 0 -8 4 -9 13 0 8 6 17 8 20 5 10 -10 79 37 73 51 -2 7 3 12 11 11 17
-1 75 73 67 86 -2 4 0 9 5 13 14 8 13 -3 18 455 3 398 -3 530 -27 515 -4 -3
-11 2 -14 11 -4 9 -2 13 5 8 19 -11 -7 29 -32 51 -24 20 -24 20 -9 0 11 -16
11 -17 0 -8 -11 10 -17 10 -29 0 -14 -10 -14 -10 -3 4 21 27 6 45 -63 75 -5 2
-11 -10 -12 -28z"
            />
        </g>
    </svg>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'RoltekLogo',
})
export default class RoltekLogo extends Vue {}
</script>

<style lang="scss" scoped></style>
