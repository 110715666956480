
import { Vue, Component } from 'vue-property-decorator';
import { IformCreateOption } from 'ant-design-vue/types/form/form';
import User from '@/models/User';
import { RouteNames } from '@/enums/routes/RouteNames';
import { LocaleMessage } from 'vue-i18n';
import RoltekLogo from '@/icons/RoltekLogo.vue';
import moment from 'moment';
import i18n from '@/i18n';
import RoltekHeaderLogo from '@/icons/RoltekHeaderLogo.vue';
import { Col, Row } from 'ant-design-vue';
import RoltekBanner from '@/icons/RoltekBanner.vue';
import { ErrorOptions } from '@/interfaces/ErrorOptions';

@Component({
    name: 'ResetPassword',
    components: {
        RoltekLogo,
        RoltekHeaderLogo,
        RoltekBanner,
        Col,
        Row,
    },
})
export default class ResetPassword extends Vue {
    private httpRequestStarted: boolean = false;
    private confirmDirty = false;

    private get form() {
        return this.$form.createForm(this, { name: 'horizontal_login' } as IformCreateOption);
    }

    private compareToFirstPassword(rule: any, value: string, callback: (errorMessage?: LocaleMessage) => {}) {
        const form = this.form;
        if (value && value !== form.getFieldValue('password')) {
            callback(this.$t('Lozinke koje ste upisali nisu iste!'));
        } else {
            callback();
        }
    }

    private validateToNextPassword(rule: any, value: string, callback: (errorMessage?: string) => {}) {
        const form = this.form;
        if (value && this.confirmDirty) {
            form.validateFields(['passwordRepeat'], { force: true });
        }
        callback();
    }

    private handleConfirmBlur(e: Event) {
        if (e == null || e.target == null) {
            return;
        }
        const inputElement = e.target as HTMLInputElement;
        const value = inputElement.value;
        this.confirmDirty = this.confirmDirty || !!value;
    }

    private hasErrors(fieldsError: any) {
        return Object.keys(fieldsError).some((field) => fieldsError[field]);
    }

    private userNameError() {
        const { getFieldError } = this.form;

        return getFieldError('username') ? getFieldError('username')[0] : '';
    }

    private passwordError() {
        const { getFieldError } = this.form;

        return getFieldError('password') ? getFieldError('password')[0] : '';
    }

    private handleSubmit(event: Event) {
        event.preventDefault();

        this.form.validateFields(
            // @ts-ignore
            async (err: Error, { password, passwordRepeat }: { password: string; passwordRepeat: string }) => {
                if (!err) {
                    this.httpRequestStarted = true;
                    try {
                        await User.resetPassword(password, passwordRepeat, this.$route.params.token);
                    } catch (e) {
                        const errorObj = e as ErrorOptions;

                        if (errorObj.response && errorObj.response.data) {
                            const fields: any = {};
                            errorObj?.response?.data?.errors?.forEach((errorDetails: any) => {
                                const field = errorDetails.source.pointer.split('/')[3];
                                if (field === 'token') {
                                    this.$notification.error({
                                        message: this.$t('Dogodila se greška') as string,
                                        description: this.$t('Poveznica više nije validna') as string,
                                    });
                                }
                                if (fields[field] == null) {
                                    fields[field] = {};
                                    fields[field].value = errorDetails.source.parameter;
                                    fields[field].errors = [];
                                }
                                fields[field].errors = [
                                    {
                                        message: errorDetails.detail,
                                        field,
                                    },
                                ];
                            });
                            this.form.setFields(fields);
                        }
                        return;
                    } finally {
                        this.httpRequestStarted = false;
                    }
                    this.$notification.success({
                        message: this.$t('Lozinka uspješno promijenjena!') as string,
                        description: '',
                    });
                    this.$router.push({ name: RouteNames.login });
                }
            }
        );
    }

    private created() {
        const userLocale = this.$route.query.locale;
        if (userLocale != null) {
            this.$i18n.locale = userLocale as string;
            moment.locale(userLocale as string);
        }
    }
}
